import React, { FC, PropsWithChildren, ReactNode } from "react";

import { Text, TextProps } from "../Text";

export type FormattedTextRule = {
  matcher: RegExp;
  render: React.FC<{ text: string }>;
};

export interface FormattedTextProps {
  rules?: FormattedTextRule[];
}

const DEFAULT_RULES: FormattedTextRule[] = [];

const formatWord = (word: string, rules: FormattedTextRule[]) => {
  let formattedWord = word as ReactNode;

  rules.forEach((rule) => {
    if (rule.matcher.test(word)) {
      formattedWord = rule.render({ text: word });
    }
  });

  return <>{formattedWord} </>;
};

export const FormattedText: FC<
  PropsWithChildren<FormattedTextProps> & TextProps
> = ({ rules = DEFAULT_RULES, children, ...props }) => {
  const childrenIsString = typeof children === "string";
  if (!childrenIsString) {
    console.error("FormattedText only accepts string children");
    return;
  }
  const childString = children as string;

  const parts = childString.split("\n");

  return (
    <>
      {parts.map((part) => (
        <Text {...props} key={part}>
          {part.split(" ").map((word) => formatWord(word, rules))}
        </Text>
      ))}
    </>
  );
};
