
const digest = '3d64813247882febae084dad33e0727b98384e2f693f2f0704eb65f1507406ce';
const css = `._wrapper_yjg99_1 {
  column-gap: 1rem;
  display: grid;
  grid-template-columns: 1fr 4fr;
  min-height: 66vh;
  padding: 0 1.5rem 2rem;
  scroll-behavior: smooth;
  --field-width: minmax(7.5rem, 11rem);
  --delete-action-width: 1.5rem;
  --drag-handle-width: max-content;
}

._sideBar_yjg99_13 {
  --offset: 1.25rem;
  position: sticky;
  top: var(--offset);
  height: max-content;
  padding: 1rem;
  margin-top: var(--offset);
}

._miniMap_yjg99_22 {
  margin-top: 1rem;
  max-height: 100%;
  overflow-y: auto;
}

._cardTitle_yjg99_28 {
  display: flex;
  font-style: normal;
  font-weight: 600;
  font-size: 1.25rem;
  line-height: 1;
  width: 100%;
  align-items: center;
}

._budgetGrid_yjg99_38 {
  position: sticky;
  z-index: 1;
  top: 0;
  display: grid;
  padding: 1rem 1rem 1rem 1.5rem;
  margin-bottom: 1rem;
  grid-column-gap: 1.5rem;
  grid-template-columns:
    1fr var(--field-width) var(--field-width) var(--field-width)
    var(--delete-action-width);
}

._cardGrid_yjg99_51 {
  display: grid;
  padding: 1rem;
  padding-left: 1.5rem;
  margin-bottom: 1rem;
  grid-column-gap: 1.5rem;
  grid-template-columns: 1fr var(--field-width) var(--delete-action-width);
}

._iconButton_yjg99_60 {
  background: transparent;
  border: none;
}

._instructions_yjg99_65 {
  color: var(--color--neutral-10);
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 0;
  max-width: 240px;
}

._miniMapItem_yjg99_73 {
  position: relative;

  color: var(--color-neutral-10);
  cursor: pointer;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  margin: 0 0 0.75rem 1.5rem;
  transition: color 0.25s var(--transition);
}

._miniMapItem_yjg99_73:before {
  position: absolute;
  top: 0.3rem;
  left: -1.25rem;

  --background: var(--project-color);
  background: var(--background);
  border: 1px solid var(--border-color);
  --border-color: var(--project-color);
  border-radius: 50%;
  content: "";
  display: block;
  height: 9px;
  overflow: visible;
  transition: scale 0.25s var(--transition);
  width: 9px;
}

._miniMapItem_yjg99_73:hover {
  --background: var(--project-color);
  color: var(--color-legacy-gray-8);
}

._miniMapItem_yjg99_73:hover:before {
  --border-color: var(--color-white);
  transform: scale(1.5);
}

._emptyStateStarter_yjg99_113 {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(50%, 1fr));
}

._emptyStateSection_yjg99_118 {
  padding: 5rem 2.5rem;
}

._emptyStateHeading_yjg99_122 {
  color: var(--color-neutral-40);
  font-size: 20px;
  font-weight: 600;
  line-height: 20px;
  margin: 0 0 1rem;
}

._emptyStateAction_yjg99_130 {
  margin-top: 1rem;
  max-width: 240px;
}

._emptyStateInstructions_yjg99_135 {
  color: var(--color--neutral-10);
  font-size: 1rem;
  line-height: 1.25;
  margin-bottom: 0;
  max-width: 240px;
}

._overBudget_yjg99_143 input,
._overBudget_yjg99_143 input:disabled,
._overBudget_yjg99_143 .field__block input {
  color: var(--color-red);
}
`;

(function() {
  if (typeof document === 'undefined') {
    return;
  }
  if (!document.getElementById(digest)) {
    var el = document.createElement('style');
    el.id = digest;
    el.textContent = css;
    document.head.appendChild(el);
  }
})();
    
export default {"wrapper":"_wrapper_yjg99_1","sideBar":"_sideBar_yjg99_13","miniMap":"_miniMap_yjg99_22","cardTitle":"_cardTitle_yjg99_28","budgetGrid":"_budgetGrid_yjg99_38","cardGrid":"_cardGrid_yjg99_51","iconButton":"_iconButton_yjg99_60","instructions":"_instructions_yjg99_65","miniMapItem":"_miniMapItem_yjg99_73","emptyStateStarter":"_emptyStateStarter_yjg99_113","emptyStateSection":"_emptyStateSection_yjg99_118","emptyStateHeading":"_emptyStateHeading_yjg99_122","emptyStateAction":"_emptyStateAction_yjg99_130","emptyStateInstructions":"_emptyStateInstructions_yjg99_135","overBudget":"_overBudget_yjg99_143"};
export { css, digest };
  