import { Link } from "../Link";

import { FormattedTextRule } from "./FormattedText";

// from https://stackoverflow.com/a/3809435
export const urlRule: FormattedTextRule = {
  matcher:
    /(http(s)?:\/\/.)?(www\.)?[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,6}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/,
  render: ({ text: link }: { text: string }) => (
    <Link href={link} target="_blank">
      {link}
    </Link>
  ),
};

export const emailRule: FormattedTextRule = {
  matcher:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  render: ({ text: link }: { text: string }) => (
    <Link href={`mailto:${link}`}>{link}</Link>
  ),
};
